<template>
  <div class="layoutContent">
    <HomeTitle :description="description" />
    <div class="contentParty">
      <k-alert-msg ref="lblMsgDocumentDetail" />
      <k-form @keydown.enter="isPrevent">
        <k-form-group
          label-suffix="documentName"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
          label-for="documentName"
        >
          <div class="col-form-label">
            {{ form.documentName }}
          </div>
        </k-form-group>
        <k-form-group
          label-suffix="instructions"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
          label-for="instructions"
        >
          <div class="col-form-label">
            {{ form.instructions }}
          </div>
        </k-form-group>
        <k-form-group
          label-suffix="relatedFiles"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
          label-for="relatedFiles"
        >
          <div class="col-form-label">
            <k-list-group class="document-detail">
              <k-list-group-item
                v-for="(item, index) in form.relatedFiles"
                :key="index"
              >
                <k-link @click="downloadPath(item.id)">
                  {{ item.displayName }}
                </k-link>
              </k-list-group-item>
            </k-list-group>
          </div>
        </k-form-group>
        <k-form-group
          label-suffix="dueDate"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
          label-for="dueDate"
        >
          <div class="col-form-label">
            {{ form.dueDate }}
          </div>
        </k-form-group>
        <k-form-group
          label-suffix="uploadFile"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
          label-for="uploadFile"
        >
          <k-list-group class="document-detail">
            <k-list-group-item
              v-for="(item, index) in form.uploadFile"
              :key="index"
            >
              <k-link @click="downloadPath(item.id)">
                {{ item.displayName }}
              </k-link>
            </k-list-group-item>
          </k-list-group>
        </k-form-group>
        <k-form-group
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
        >
          <k-button
            variant="outline-secondary"
            @click="backClickEvent()"
            type="button"
          >
            back
          </k-button>
        </k-form-group>
      </k-form>
    </div>
  </div>
</template>

<script>
import { pageNameEnum } from "@/utils/enums/pageNameEnum";
import HomeTitle from "@/views/components/homeTitle";
import { isPrevent, repResult } from "fe-infrastractures";
import { getPortalPageSetting } from "@/api/home";
import {
  getRequiredDocumensDetail,
  documentIsExit,
  downloadDocumentUrl,
} from "@/api/requiredDocuments.js";
export default {
  components: {
    HomeTitle,
  },
  props: {
    isPlaintext: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPrevent,
      description: "",
      form: {},
    };
  },
  computed: {},
  created() {
    this.initForm();
    getPortalPageSetting(pageNameEnum.RequiredDocumentsSubmit).then((data) => {
      this.description = data.data.description;
    });
  },
  methods: {
    backClickEvent() {
      let query = {
        tabIndex: this.$route.query.tabIndex,
      };
      this.$router.push({
        name: this.$route.query.returnUrl ?? "requiredDocuments",
        query: query,
        params: {
          tabIndex: this.$route.query.tabIndex,
        },
      });
    },
    initForm() {
      let query = {
        requiredDocumentationProcessingId: this.$route.query.detailId,
        electionId: this.$route.query.electionId,
      };
      getRequiredDocumensDetail(query).then((result) => {
        this.form = result.data;
      });
    },
    downloadPath(documentId) {
      let model = {
        DocumentId: documentId,
        ...this.downloadParams,
      };
      documentIsExit(model).then((m) => {
        if (m.data.status === repResult.success) {
          downloadDocumentUrl(model);
        } else {
          this.$refs.lblMsgDocumentDetail.message(m.data.status, m.message);
        }
      });
    },
  },
};
</script>

<style>
.document-detail {
  line-height: 2.4em;
}
</style>
